import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Ingredients from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    INGREDIENTS,
    INGREDIENTS_SUBSCRIPTION,
    ADD_INGREDIENT,
    UPDATE_INGREDIENT,
    DELETE_INGREDIENT,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

const limit = 10;

export default function IngredientsContainer() {
    const [addIngredient] = useMutation(ADD_INGREDIENT);
    const [updateIngredient] = useMutation(UPDATE_INGREDIENT);
    const [deleteIngredient] = useMutation(DELETE_INGREDIENT);

    const [page, setPage] = useState(0);

    const {
        data: ingredientsData,
        loading: ingredientsLoading,
        refetch: ingredientsRefetch,
    } = useQuery(INGREDIENTS, {
        variables: {
            offset: page * limit,
            limit,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            ingredientsRefetch({
                offset: page * limit,
                limit,
            });
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const pages = useMemo(() => {
        if (!ingredientsData || !ingredientsData.ingredients) {
            return [];
        }
        let result = [];
        /*  for (let index = 0; index < Math.ceil(ingredientsData.ingredients.count / limit); index++) {
            result.push({
                label: index + 1,
                value: index,
            });
        }*/
        return result;
    }, [ingredientsData]);

    const onCreateIngredient = (data, globalIngredientConversions) => {
        addIngredient({
            variables: {
                allergens: data.allergens.map((allergen) => allergen.value),
                averagePrice:
                    parseFloat(data.averagePrice) > 0
                        ? parseFloat(parseFloat(data.averagePrice).toFixed(2))
                        : 0.0,
                categoryId: data.categoryId,
                conversions: data.conversions.map((conversion) => ({
                    toUnit: conversion.toUnit,
                    multiplier:
                        parseFloat(conversion.multiplier) > 0
                            ? parseFloat(conversion.multiplier)
                            : globalIngredientConversions.find(
                                  (conv) => conv.toUnit === conversion.toUnit
                              )?.multiplier ?? 1,
                })),
                conversionsVerified: data.conversionsVerified,
                fats: parseFloat(data.fats),
                fiber: parseFloat(data.fiber),
                intoleranceIds: data.intolerances.map((intolerance) => intolerance.value),
                kcal: parseFloat(data.kcal),
                proteins: parseFloat(data.proteins),
                saccharides: parseFloat(data.saccharides),
                sugars: parseFloat(data.sugars),
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
                units: data.units.map((unit) => unit.value),
                visible: data.visible,
            },
        });
    };

    const onUpdateIngredient = (data, globalIngredientConversions) => {
        updateIngredient({
            variables: {
                id: data.id,
                allergens: data.allergens.map((allergen) => allergen.value),
                averagePrice:
                    parseFloat(data.averagePrice) > 0
                        ? parseFloat(parseFloat(data.averagePrice).toFixed(2))
                        : 0.0,
                categoryId: data.categoryId,
                conversions: data.conversions.map((conversion) => ({
                    toUnit: conversion.toUnit,
                    multiplier:
                        parseFloat(conversion.multiplier) > 0
                            ? parseFloat(conversion.multiplier)
                            : globalIngredientConversions.find(
                                  (conv) => conv.toUnit === conversion.toUnit
                              )?.multiplier ?? 1,
                })),
                conversionsVerified: data.conversionsVerified,
                fats: parseFloat(data.fats),
                fiber: parseFloat(data.fiber),
                intoleranceIds: data.intolerances.map((intolerance) => intolerance.value),
                kcal: parseFloat(data.kcal),
                proteins: parseFloat(data.proteins),
                saccharides: parseFloat(data.saccharides),
                sugars: parseFloat(data.sugars),
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
                units: data.units.map((unit) => unit.value),
                visible: data.visible,
            },
        });
    };

    const onRemoveIngredient = (id) => {
        deleteIngredient({
            variables: {
                id: id,
            },
        });
    };

    if (ingredientsLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <Ingredients
                ingredients={
                    ingredientsData && ingredientsData.ingredients
                        ? ingredientsData.ingredients
                        : []
                }
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreateIngredient={onCreateIngredient}
                onUpdateIngredient={onUpdateIngredient}
                onRemoveIngredient={onRemoveIngredient}
                pages={pages}
                page={page}
                setPage={setPage}
            />
        </Col>
    );
}
