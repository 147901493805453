import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { GLOBAL_INGREDIENT_CONVERSIONS, ADD_OR_UPDATE_GLOBAL_INGREDIENT_CONVERSION } from 'queries';
import { Input, Button, Card, Row, Col, Table, Spinner } from 'reactstrap';
import Select from 'react-select';

const UNITS = [
    {
        label: 'Piece',
        value: 'piece',
    },
    {
        label: 'Liter',
        value: 'liter',
    },
    {
        label: 'Teaspoon',
        value: 'teaspoon',
    },
    {
        label: 'Pinch',
        value: 'pinch',
    },
    {
        label: 'Tablespoon',
        value: 'tablespoon',
    },
    {
        label: 'Handful',
        value: 'handful',
    },
];

export default function GlobalIngredientConversion() {
    const [addOrUpdateGlobalIngredientConversion] = useMutation(
        ADD_OR_UPDATE_GLOBAL_INGREDIENT_CONVERSION
    );

    const [openEdit, setOpenEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [newUnit, setNewUnit] = useState(null);
    const [newMultiplier, setNewMultiplier] = useState(1.0);

    const {
        data: globalIngredientConversionsData,
        loading: globalIngredientConversionsLoading,
        refetch: globalIngredientConversionsRefetch,
    } = useQuery(GLOBAL_INGREDIENT_CONVERSIONS, {
        fetchPolicy: 'network-only',
    });

    const onAddOrUpdateGlobalIngredientConversion = (data) => {
        addOrUpdateGlobalIngredientConversion({
            variables: {
                toUnit: data.toUnit,
                multiplier: parseFloat(data.multiplier),
            },
        }).then(() => {
            globalIngredientConversionsRefetch().then(() => {
                setNewMultiplier(1.0);
                setNewUnit(null);
                setShowAdd(false);

                setOpenEdit(false);
            });
        });
    };

    if (globalIngredientConversionsLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Row>
                    <Spinner color="primary" />
                </Row>
            </Card>
        );
    }

    console.log(newUnit, newMultiplier);

    return (
        <Card style={{ padding: '1em' }}>
            <Row xs={openEdit ? '2' : ''}>
                {!openEdit && (
                    <Col>
                        <Button
                            color="warning"
                            onClick={() => {
                                setOpenEdit(true);
                            }}
                        >
                            Upraviť
                        </Button>
                    </Col>
                )}

                {openEdit && (
                    <Col>
                        <Button
                            onClick={() => {
                                setOpenEdit(false);
                            }}
                        >
                            Zrušiť
                        </Button>
                    </Col>
                )}
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th width="33%">Hodnota</th>
                            <th width="33%">{openEdit ? 'Množstvo' : 'Konverzia'}</th>
                            {openEdit && <th width="33%">Akcia</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {(globalIngredientConversionsData &&
                        globalIngredientConversionsData.globalIngredientConversions
                            ? globalIngredientConversionsData.globalIngredientConversions
                            : []
                        ).length === 0 &&
                            !openEdit && (
                                <tr>
                                    <td colSpan={3}>Žiadne konverzie</td>
                                </tr>
                            )}

                        {!showAdd && openEdit && (
                            <tr key="add-globalIngredientConversion-btn">
                                <td
                                    colSpan={2}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setShowAdd(true);
                                    }}
                                >
                                    + Konverzia
                                </td>
                            </tr>
                        )}
                        {showAdd && openEdit && (
                            <tr key="new-globalIngredientConversion">
                                <td>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="ingredient-unit"
                                        options={UNITS.filter(
                                            (unit) =>
                                                !globalIngredientConversionsData.globalIngredientConversions.find(
                                                    (conversion) => conversion.toUnit === unit.value
                                                )
                                        )}
                                        value={newUnit}
                                        onChange={(e) => {
                                            setNewUnit(e);
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        id={`new-globalIngredientConversion-multiplier`}
                                        value={newMultiplier}
                                        type="number"
                                        min={0}
                                        step={0.1}
                                        onChange={(e) => {
                                            setNewMultiplier(e.target.value);
                                        }}
                                    />
                                </td>
                                <td>
                                    <Row xs="2">
                                        <Col>
                                            <Button
                                                color="secondary"
                                                block
                                                onClick={() => {
                                                    setNewMultiplier(1.0);
                                                    setNewUnit(null);
                                                    setShowAdd(false);
                                                }}
                                            >
                                                Zrušiť
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                color="primary"
                                                block
                                                disabled={!newMultiplier || !newUnit}
                                                onClick={() => {
                                                    onAddOrUpdateGlobalIngredientConversion({
                                                        toUnit: newUnit.value,
                                                        multiplier: newMultiplier,
                                                    });
                                                }}
                                            >
                                                Uložiť
                                            </Button>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        )}
                        {showAdd && openEdit && newUnit && (
                            <tr key="new-globalIngredientConversion-info">
                                <td colSpan={3}>
                                    <bold>{`${newMultiplier} gramov = 1 ${newUnit.label}`}</bold>
                                </td>
                            </tr>
                        )}
                        {(globalIngredientConversionsData &&
                        globalIngredientConversionsData.globalIngredientConversions
                            ? globalIngredientConversionsData.globalIngredientConversions
                            : []
                        ).map((globalIngredientConversion) => (
                            <tr key={globalIngredientConversion.toUnit}>
                                <td>{globalIngredientConversion.toUnit}</td>
                                {openEdit && (
                                    <td>
                                        <Input
                                            id={`multiplier-${globalIngredientConversion.toUnit}`}
                                            defaultValue={globalIngredientConversion.multiplier}
                                            type="number"
                                            min={0}
                                            step={0.1}
                                            onBlur={() => {
                                                const value = window.document.getElementById(
                                                    `multiplier-${globalIngredientConversion.toUnit}`
                                                )?.value;
                                                if (value) {
                                                    onAddOrUpdateGlobalIngredientConversion({
                                                        toUnit: globalIngredientConversion.toUnit,
                                                        multiplier: value,
                                                    });
                                                }
                                            }}
                                        />
                                    </td>
                                )}
                                <td
                                    style={{
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    {`${globalIngredientConversion.multiplier} g = 1 ${globalIngredientConversion.toUnit}`}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </Card>
    );
}
