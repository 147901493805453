import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link, useParams } from 'react-router-dom';

export default function PotentialIngredientsSidebar() {
    const { ingredientID } = useParams();

    return (
        <Nav fill pills vertical>
            <NavItem>
                <NavLink tag={Link} to={`/potential-ingredients`} end>
                    ← Naspäť na zoznam
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} to={`/potential-ingredients/${ingredientID}`}>
                    Detail
                </NavLink>
            </NavItem>
        </Nav>
    );
}
