import React, { useMemo, useState } from 'react';
import {
    Row,
    Col,
    Table,
    Spinner,
    Label,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    Button,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import {
    POTENTIAL_INGREDIENTS,
    POTENTIAL_INGREDIENTS_SUBSCRIPTION,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import Select from 'react-select';
import { sortBasic, sortName } from 'configs/sortOptions';

const limit = 10;

export default function PotentialIngredientsTable() {
    const navigate = useNavigate();

    const [sortOption, setSortOption] = useState(sortBasic);
    const [showOnlyVerified, setShowOnlyVerified] = useState(true);
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    const [declineReason, setDeclineReason] = useState('');
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [sendEmails, setSendEmails] = useState(true);

    const [page, setPage] = useState(0);

    const {
        data: ingredientsData,
        loading: ingredientsLoading,
        refetch: ingredientsRefetch,
    } = useQuery(POTENTIAL_INGREDIENTS, {
        variables: {
            offset: page * limit,
            limit,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(POTENTIAL_INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            ingredientsRefetch({
                offset: page * limit,
                limit,
            });
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const pages = useMemo(() => {
        if (!ingredientsData || !ingredientsData.ingredients) {
            return [];
        }
        let result = [];
        /*  for (let index = 0; index < Math.ceil(ingredientsData.ingredients.count / limit); index++) {
            result.push({
                label: index + 1,
                value: index,
            });
        }*/
        return result;
    }, [ingredientsData]);

    if (ingredientsLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    const languages =
        languagesData && languagesData.languages
            ? languagesData.languages.map((lang) => ({
                  ...lang,
                  label: lang.title,
                  value: lang.id,
              }))
            : [];

    return (
        <Row
            style={{
                paddingTop: '1em',
            }}
        >
            <Col>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={false}
                    name="ingredient-sort"
                    options={[sortBasic, sortName]}
                    value={sortOption}
                    onChange={(option) => {
                        setSortOption(option);
                    }}
                />
                <Table hover>
                    <thead>
                        <tr>
                            {languages.map((lang) => (
                                <th key={lang.value}>{lang.label}</th>
                            ))}
                            <th width={'5%'}>Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...ingredientsData.ingredients]
                            .sort((c1, c2) => {
                                if (sortOption.value === 'id') {
                                    return c1[sortOption.value] < c2[sortOption.value] ? -1 : 1;
                                }
                                return c1[sortOption.value].toLowerCase() <
                                    c2[sortOption.value].toLowerCase()
                                    ? -1
                                    : 1;
                            })
                            .map((ingredient) => (
                                <tr
                                    key={ingredient.id}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    {languages.map((lang) => (
                                        <td
                                            key={`${ingredient.id}-${lang.value}`}
                                            onClick={() =>
                                                navigate(
                                                    `/potential-ingredients/${ingredient.id}/profile`
                                                )
                                            }
                                        >
                                            {ingredient.translations.find(
                                                (translation) =>
                                                    translation.language.id === lang.value
                                            )?.title ?? '-'}
                                        </td>
                                    ))}
                                    <td className="ta-center">
                                        <Row xs="2">
                                            <Col>
                                                <MaterialSymbol
                                                    icon={'edit'}
                                                    size={24}
                                                    grade={-25}
                                                    color={'orange'}
                                                    style={{
                                                        outline: 'none',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                    onClick={() => {}}
                                                />
                                            </Col>
                                            <Col>
                                                <MaterialSymbol
                                                    icon={'cancel'}
                                                    size={24}
                                                    grade={-25}
                                                    color={'red'}
                                                    style={{
                                                        outline: 'none',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                    onClick={() => {
                                                        if (window.confirm('Ste si isty?')) {
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>

                <Modal
                    isOpen={showDeclineModal}
                    toggle={() => {
                        setShowDeclineModal(false);
                    }}
                >
                    <ModalHeader>Odmietnuť žiadosti?</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup check>
                                <Input
                                    id="send-decline-emails"
                                    type="checkbox"
                                    checked={sendEmails}
                                    onChange={() => {
                                        setSendEmails(!sendEmails);
                                    }}
                                />
                                <Label check htmlFor="send-decline-emails">
                                    Odoslať email o zamietnutí.
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="decline-reason">Dôvod?</Label>
                                <Input
                                    id="decline-reason"
                                    value={declineReason}
                                    onChange={(e) => {
                                        setDeclineReason(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <Row xs="2">
                                <Col>
                                    <Button
                                        color="secondary"
                                        block
                                        onClick={() => {
                                            setShowDeclineModal(false);
                                        }}
                                    >
                                        Zavrieť
                                    </Button>
                                </Col>
                                <Col className="flex jc-flex-end">
                                    <Button
                                        color={'danger'}
                                        block
                                        onClick={() => {
                                            setShowDeclineModal(false);
                                        }}
                                    >
                                        Odmietnuť
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </Col>
        </Row>
    );
}
