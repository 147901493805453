import React from 'react';
import { useQuery } from '@apollo/client';
import { POTENTIAL_INGREDIENT } from 'queries';
import NewIngredientForm from './form';

export default function NewIngredientLoader(props) {
    const { newIngredientId } = props;

    const { data: newIngredientData, loading: newIngredientLoading } = useQuery(
        POTENTIAL_INGREDIENT,
        {
            variables: {
                newIngredientId,
            },
            fetchPolicy: 'network-only',
        }
    );

    return (
        <NewIngredientForm
            {...props}
            newIngredientData={newIngredientData}
            newIngredientLoading={newIngredientLoading}
        />
    );
}
