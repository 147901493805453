import React, { useState } from 'react';
import { Table, Row, Col, Modal, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import IngredientForm from './form';
import IngredientLoader from './loader';
import { MaterialSymbol } from 'react-material-symbols';

export default function Ingredients(props) {
    const {
        ingredients,
        languages,
        onCreateIngredient,
        onUpdateIngredient,
        onRemoveIngredient,
        pages,
        page,
        setPage,
    } = props;

    const [editIngredient, setEditIngredient] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th>Konverzie OK</th>
                        {languages.map((lang) => (
                            <th key={lang.value}>{lang.label}</th>
                        ))}
                        <th width={'5%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-ingredient-btn">
                        <td
                            colSpan={2 + languages.length}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setEditIngredient(null);
                                toggle();
                            }}
                        >
                            + Ingrediencia
                        </td>
                    </tr>
                    {ingredients.map((ingredient) => (
                        <tr
                            key={ingredient.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <td>
                                <MaterialSymbol
                                    icon={
                                        ingredient.conversionsVerified ? 'check_circle' : 'cancel'
                                    }
                                    size={24}
                                    grade={-25}
                                    color={ingredient.conversionsVerified ? 'green' : 'red'}
                                />
                            </td>
                            {languages.map((lang) => (
                                <td key={`${ingredient.id}-${lang.value}`}>
                                    {ingredient.translations.find(
                                        (translation) => translation.language.id === lang.value
                                    )?.title ?? '-'}
                                </td>
                            ))}
                            <td>
                                <Row xs="2">
                                    <Col>
                                        <MaterialSymbol
                                            icon={'edit'}
                                            size={24}
                                            grade={-25}
                                            color={'orange'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                setEditIngredient(ingredient);
                                                toggle();
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <MaterialSymbol
                                            icon={'cancel'}
                                            size={24}
                                            grade={-25}
                                            color={'red'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                if (window.confirm('Ste si isty?')) {
                                                    onRemoveIngredient(ingredient.id);
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination>
                <PaginationItem>
                    <PaginationLink
                        first
                        onClick={() => {
                            setPage(0);
                        }}
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        previous
                        onClick={() => {
                            setPage(page - 1);
                        }}
                    />
                </PaginationItem>

                {pages.map((p) => (
                    <PaginationItem
                        key={p.value}
                        onClick={() => {
                            setPage(p.value);
                        }}
                        active={page === p.value}
                    >
                        <PaginationLink tag={'button'}>{p.label}</PaginationLink>
                    </PaginationItem>
                ))}

                <PaginationItem>
                    <PaginationLink
                        onClick={() => {
                            setPage(page + 1);
                        }}
                        next
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        last
                        onClick={() => {
                            setPage(page + 1 /*Math.ceil(recipesData.recipes.count / limit) - 1*/);
                        }}
                    />
                </PaginationItem>
            </Pagination>

            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditIngredient(null);
                    }
                    toggle();
                }}
            >
                {editIngredient && (
                    <IngredientLoader
                        onClose={() => {
                            setEditIngredient(null);
                            toggle();
                        }}
                        onSave={(data, conversions) => {
                            onUpdateIngredient(data, conversions);
                            toggle();
                        }}
                        ingredientId={editIngredient ? editIngredient.id : null}
                        languages={languages}
                    />
                )}
                {!editIngredient && (
                    <IngredientForm
                        onClose={() => {
                            toggle();
                        }}
                        onSave={(data, conversions) => {
                            onCreateIngredient(data, conversions);
                            toggle();
                        }}
                        ingredientId={null}
                        languages={languages}
                    />
                )}
            </Modal>
        </>
    );
}
