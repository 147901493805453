import { gql } from '@apollo/client';

export const INGREDIENTS_SUBSCRIPTION = gql`
    subscription ingredientsSubscription {
        ingredientsSubscription
    }
`;

export const INGREDIENTS = gql`
    query ingredients($search: String, $languageId: Int, $limit: Int, $offset: Int) {
        ingredients(search: $search, languageId: $languageId, limit: $limit, offset: $offset) {
            id
            conversionsVerified
            translations {
                title
                language {
                    id
                }
            }
        }
    }
`;

export const INGREDIENT = gql`
    query ingredient($id: Int!) {
        ingredient(id: $id) {
            id
            units
            conversionsVerified
            translations {
                title
                language {
                    id
                }
            }
            conversions {
                toUnit
                multiplier
            }
            category {
                id
                translations {
                    language {
                        id
                        languageCode
                    }
                    title
                }
            }
            intolerances {
                id
                translations {
                    title
                    language {
                        id
                        languageCode
                    }
                }
            }
            averagePrice
            allergens
            fats
            fiber
            kcal
            proteins
            saccharides
            sugars
            visible
        }
    }
`;

export const ADD_INGREDIENT = gql`
    mutation addIngredient(
        $allergens: [Int!]!
        $categoryId: Int!
        $conversions: [IngredientConversionInput!]!
        $conversionsVerified: Boolean
        $fats: Float
        $fiber: Float
        $intoleranceIds: [Int!]!
        $kcal: Int!
        $proteins: Float
        $saccharides: Float
        $sugars: Float
        $translations: [IngredientTranslationInput!]!
        $units: [IngredientUnitEnum!]!
        $visible: Boolean!
        $averagePrice: Float
    ) {
        addIngredient(
            allergens: $allergens
            categoryId: $categoryId
            conversions: $conversions
            conversionsVerified: $conversionsVerified
            fats: $fats
            fiber: $fiber
            intoleranceIds: $intoleranceIds
            kcal: $kcal
            proteins: $proteins
            saccharides: $saccharides
            sugars: $sugars
            translations: $translations
            units: $units
            visible: $visible
            averagePrice: $averagePrice
        ) {
            id
        }
    }
`;

export const UPDATE_INGREDIENT = gql`
    mutation updateIngredient(
        $id: Int!
        $allergens: [Int!]!
        $categoryId: Int!
        $conversions: [IngredientConversionInput!]!
        $conversionsVerified: Boolean
        $fats: Float
        $fiber: Float
        $intoleranceIds: [Int!]!
        $kcal: Int!
        $proteins: Float
        $saccharides: Float
        $sugars: Float
        $translations: [IngredientTranslationInput!]!
        $units: [IngredientUnitEnum!]!
        $visible: Boolean!
        $averagePrice: Float
    ) {
        updateIngredient(
            id: $id
            allergens: $allergens
            categoryId: $categoryId
            conversions: $conversions
            conversionsVerified: $conversionsVerified
            fats: $fats
            fiber: $fiber
            intoleranceIds: $intoleranceIds
            kcal: $kcal
            proteins: $proteins
            saccharides: $saccharides
            sugars: $sugars
            translations: $translations
            units: $units
            visible: $visible
            averagePrice: $averagePrice
        ) {
            id
        }
    }
`;

export const DELETE_INGREDIENT = gql`
    mutation deleteIngredient($id: Int!) {
        deleteIngredient(id: $id) {
            id
        }
    }
`;
