import { gql } from '@apollo/client';

export const POTENTIAL_CHEFS_SUBSCRIPTION = gql`
    subscription potentialChefsSubscription {
        potentialChefsSubscription
    }
`;

export const POTENTIAL_CHEFS = gql`
    query potentialChefs($verified: Boolean) {
        potentialChefs(verified: $verified) {
            id
            fullName
            instagram
            youtube
            nickname
            verified
        }
    }
`;

export const GET_POTENTIAL_CHEF = gql`
    query potentialChef($id: Int!) {
        potentialChef(id: $id) {
            id
            fullName
            email
            instagram
            youtube
            phoneNumber
            nickname
            createdAt
            language {
                id
                languageCode
                title
            }
        }
    }
`;

export const CHEF_REGISTRATION_OPEN = gql`
    query generalSetting {
        generalSetting {
            openChefRegistration
        }
    }
`;

export const CHANGE_CHEF_REGISTRATION_OPEN = gql`
    mutation updateGeneralSetting($openChefRegistration: Boolean!) {
        updateGeneralSetting(openChefRegistration: $openChefRegistration) {
            openChefRegistration
        }
    }
`;

export const ACCEPT_POTENTIAL_CHEFS = gql`
    mutation acceptPotentialChef($id: Int!) {
        acceptPotentialChef(id: $id)
    }
`;

export const DECLINE_POTENTIAL_CHEFS = gql`
    mutation declinePotentialChef($id: Int!, $reason: String) {
        declinePotentialChef(id: $id, reason: $reason)
    }
`;

export const MASS_DECLINE_POTENTIAL_CHEFS = gql`
    mutation massDeclinePotentialChefs($ids: [Int!]!, $sendEmails: Boolean!, $reason: String) {
        massDeclinePotentialChefs(ids: $ids, sendEmails: $sendEmails, reason: $reason)
    }
`;
